const css = `.modal {
	display: flex;
	align-items: center;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    position: fixed;
    z-index: 40;
	inset: 0;
}
.modal-background {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
	background-color: rgba(10,10,10,.86);
}
.modal-content {
	margin: 0 20px;
    max-height: calc(100vh - 160px);
    overflow: auto;
    position: relative;
    width: 100%;
}
@media screen and (min-width: 769px) {
.modal-content {
    margin: 0 auto;
    max-height: calc(100vh - 40px);
    width: 640px;
}
}
.modal-box {
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 0.5em 1em -0.125em rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.02);
    color: #4a4a4a;
    display: block;
    padding: 1.25rem;
}
`;

const checkNewBreaks = async () => {
	const url: string = (window as any).polrajBeHost + '/breaks';
	const res = await fetch(url);
	const breaks = await res.json();
	for (const breakObj of breaks) {
		const key = `break-shown-${breakObj._id}`;
		if (localStorage.getItem(key)) continue;
		const now = new Date();
		const start = new Date(breakObj.startShowing || breakObj.start);
		const end = new Date(breakObj.end);
		if (now > start && now < end) {
			displayBreak(breakObj);
			localStorage.setItem(key, 'true');
			break;
		}
	}
};

const displayBreak = (breakObj: any) => {
	const cssEl = document.createElement('STYLE');
	cssEl.textContent = css;
	document.body.appendChild(cssEl);
	const modal = document.createElement('DIV');
	modal.classList.add('modal');
	modal.innerHTML = `  <div class="modal-background"></div>
	<div class="modal-content"><div class="modal-box">
	  ${breakObj.texts[(window as any).polrajCurrentLang]}
	  </div></div>
	<button class="modal-close is-large" aria-label="close"></button>`;
	document.body.appendChild(modal);
	document
		.querySelector('.modal-background')
		?.addEventListener('click', () => {
			modal.remove();
		});
};

checkNewBreaks();
